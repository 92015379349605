<template>
    <div>
        <v-card>
            <s-toolbar label="Certificados y Constancias" dark color="#8e8f91" save @save="save()"></s-toolbar>
            <v-container>
                <v-row>
                    <v-col sm="3" md="4">
                        <s-toolbar-person label="Buscar Persona" v-model="PrsDocumentNumber" noCamera noEdit @returnPerson="returnPerson($event)"/>
                    </v-col>
                    <v-col>
                        <s-text v-model="NtpFullName" label="Apellidos y Nombres" readonly>
                        </s-text>
                    </v-col>
                    <v-col sm="3" md="3">
                        <s-select-definition
                            :def="1536"
                            v-model="TypeLaborDocument"
                            label="Tipo documento"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            item-key="AmsID"
                            dense
                            fixed-header
                            :items="itemsSearch"
                            :headers="headersSearch"
                            :items-per-page="10"
                            @click:row="rowClickSearch"
                            v-model="selectedSearch"
                            show-select
                            group-by="AgtID"
                            :group-desc="true"
                        >
                            <template v-slot:group.header="{ items, isOpen, toggle }">
                                <th colspan="100%">
                                    <v-btn icon @click="toggle">
                                        <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
                                        <v-icon v-else>mdi-chevron-right</v-icon>
                                    </v-btn>
                                    <span class="font-weight-bold subtitle-1">
                                        Contrato ID: {{ items[0].AgtID }} 
                                    </span>
                                </th>
                            </template>
                            <template v-slot:[`item.SecStatus`]="{ item }">
                                <v-chip 
                                    small 
                                    :color="item.SecStatus == 1 ? 'success' : ''" 
                                    :text-color="item.SecStatus == 1 ? 'white' : ''" 
                                    >
                                    {{ item.SecStatus == 1  ? 'Activo' : 'Inactivo' }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.AmsStartDateMovement`]="{ item }">
                                {{ $fun.formatDateView(item.AmsStartDateMovement) }}
                            </template>
                            <template v-slot:[`item.AmsEndDateMovement`]="{ item }">
                                {{ $fun.formatDateView(item.AmsEndDateMovement) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <br>
        <v-card>
            <s-toolbar
            label="Certificados Emitidos" 
            dark color="#8e8f91" 
            pdf 
            @pdf="downloadPdf()"
            searchInput
            @textSearch="textSearch($event)" 
            remove
            @removed="removeCertificate()"
            :remove="$fun.isAdmin()"
            ></s-toolbar>
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table
                            item-key="AgcID"
                            dense
                            fixed-header
                            :items="itemsSearchEmit"
                            :headers="headersCertificate"
                            :items-per-page="10"
                            @click:row="rowClickSearchEmit"
                            v-model="selectedSearchEmit"
                        >
                        <template v-slot:item.SecCreate="{ item }">
                            {{ $fun.formatDateView(item.SecCreate) }}
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-dialog v-if="dialogGuidePdf" v-model="dialogGuidePdf" width="unset" persistent>
            <guide-pdf 
                v-if="isMultipleContracts && fullContractData.length > 1"  
                :contractSelected="contractSelected" 
                :fullData="fullContractData"
                @close="dialogGuidePdf = false">
            </guide-pdf>
                        
            <guide-one-pdf 
                v-else 
                :contractSelected="contractSelected" 
                :fullData="fullContractData"
                @close="dialogGuidePdf = false">
            </guide-one-pdf>
        </v-dialog>
        <br>
        <v-card>
            <s-toolbar
            label="Constancias de Trabajo Emitidos" 
            dark color="#8e8f91" 
            pdf 
            @pdf="downloadPdfContrat()"
            searchInput
            @textSearch="textSearchContract($event)" 
            remove
            @removed="removeConstant()"
            :remove="$fun.isAdmin()"
            ></s-toolbar>
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table
                            item-key="AgcID"
                            dense
                            fixed-header
                            :items="itemsSearchEmitConstant"
                            :headers="headersConstant"
                            :items-per-page="10"
                            @click:row="rowClickSearchEmitConstant"
                            v-model="selectedSearchEmitConstant"
                        >
                        <template v-slot:item.SecCreate="{ item }">
                            {{ $fun.formatDateView(item.SecCreate) }}
                        </template>
                        <template v-slot:item.AmsStartDateMovement="{ item }">
                            {{ $fun.formatDateView(item.AmsStartDateMovement) }}
                        </template>
                        <template v-slot:item.AmsEndDateMovement="{ item }">
                            {{ $fun.formatDateView(item.AmsEndDateMovement) }}
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-dialog v-if="dialogGuidePdfContrat" v-model="dialogGuidePdfContrat" width="unset" persistent>
            <contract-one-pdf
                :contractSelectedContrat="contractSelectedContrat" 
                @close="dialogGuidePdfContrat = false">
            </contract-one-pdf>
        </v-dialog>
    </div>
</template>

<script>

import SText from '../../../components/Utils/SText.vue';
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import ContractList from '../../../services/HumanResource/ContractList';
import _sContractService from '@/services/HumanResource/HmnContractService.js';
import GuidePdf from './HmnPrintGuidePdf.vue';
import GuideOnePdf from './HmnPrintGuideOnePdf.vue';
import ContractOnePdf from './HmnPrintGuideContratPdf.vue';
import { remove } from 'jszip';

export default {
    components: {
        SText,
        sToolbarPerson,
        GuidePdf,
        GuideOnePdf,
        ContractOnePdf
    },
    data() {
        return {
            contractSelected: {},
            contractSelectedContrat: {},
            fullContractData: null,
            isMultipleContracts: false,
            contractListItem : {},
            dialogGuidePdf: false,
            dialogGuidePdfContrat: false,
            selectedSearch: [],
            selectedSearchEmit: [],
            selectedSearchEmitConstant: [],
            NtpFullName: "",
            PrsDocumentNumber: "",
            filter: {
                AgcID: 0,
                IsAdmin: this.$fun.isAdmin() ? 1 : 0,
            },
            TypeLaborDocument: 1,
            headersSearch: [
                { text: "ID"                              , value: "AmsID"                 , sortable: false   , width: 20,  },
                { text: "Nombres y Apellidos Completos"   , value: "NtpFullName"           , sortable: false   , width: 20,  },
                { text: "Inicio Movimiento"               , value: "AmsStartDateMovement"  , sortable: false   , width: 80,  },
                { text: "Fin Movimiento"                  , value: "AmsEndDateMovement"    , sortable: false   , width: 80,  },
                { text: "Estado del Movimiento"           , value: "SecStatus"             , sortable: false   , width: 80,  },
                { text: "Estado del Trabajador"           , value: "AmsStatusName"         , sortable: false   , width: 80,  },
                { text: "Tipo de Planilla"                , value: "TypePayrollName"       , sortable: false   , width: 100, },
                { text: "Área"                            , value: "AreName"               , sortable: false   , width: 100, },
                { text: "Centro de Costo"                 , value: "CceName"               , sortable: false   , width: 100, },
                { text: "Cargo"                           , value: "PstName"               , sortable: false   , width: 100, },
                { text: "Tipo de Contrato"                , value: "TypeContractName"      , sortable: false   , width: 80,  },
                { text: "Tipo de Trabajador"              , value: "TypeWorkerName"        , sortable: false   , width: 80,  },
            ],
            headersCertificate: [
                {text: "ID"									, value: "AgcID"},
                {text: "Fec. Generación"					, value: "SecCreate"},
                {text: "N° Doc"								, value: "PrsDocumentNumber"},
                {text: "Nombres y Apellidos Completos"		, value: "NtpFullName"},
                {text: "Dirección"							, value: "PadAddress"},
                {text: "Distrito-Provincia-Departamento"	, value: "DisProDep"},
            ],
            headersConstant: [
                {text: "ID"									, value: "AgcID"},
                {text: "Fec. Generación"					, value: "SecCreate"},
                {text: "N° Doc"								, value: "PrsDocumentNumber"},
                {text: "Nombres y Apellidos Completos"		, value: "NtpFullName"},
                {text: "Inicio de Movimiento"               , value: "AmsStartDateMovement"     , sortable: false   , width: 80,  },
                {text: "Fin de Movimiento"                  , value: "AmsEndDateMovement"       , sortable: false   , width: 80,  },
                {text: "Área"                               , value: "AreName"          , sortable: false   , width: 100, },
                {text: "Cargo"                              , value: "PstName"          , sortable: false   , width: 100, },
                {text: "Dirección"							, value: "PadAddress"},
                {text: "Distrito-Provincia-Departamento"	, value: "DisProDep"},
            ],
            itemsSearch: [
            ],
            itemsSearchEmit: [
            ],
            itemsSearchEmitConstant: [
            ],
            dateKeys : [
                "AmsStartDateMovement",
                "AmsEndDateMovement",
                "NtpBirthDate",
                "SecCreate"
            ]
        };
    },
    methods: {
        async fetchContractList() {
            const response = await ContractList.list(this.PrsDocumentNumber, this.$fun.getUserID());
            if (response.status === 200) {
                this.itemsSearch = response.data;
            }
        },
        async fetchContractListEmit() {
            this.filter.PrsDocumentNumber = this.PrsDocumentNumber;
            await ContractList.listEmit(this.filter, this.$fun.getUserID())
            .then((r) => {
                this.itemsSearchEmit = r.data;
            })
        },
        async fetchContractListEmitConstant() {
            this.filter.PrsDocumentNumber = this.PrsDocumentNumber;
            await ContractList.listEmitConstant(this.filter, this.$fun.getUserID())
            .then((r) => {
                this.itemsSearchEmitConstant = r.data;
            })
        },
        async returnPerson(event) {
            if(event){
                this.NtpFullName = event.NtpFullName;
                this.PrsDocumentNumber = event.PrsDocumentNumber;
                this.NtpID = event.NtpID;
                this.PrsID = event.PrsID;
                this.WkrID = event.WkrID;
                console.log(event);
                
                await this.fetchContractList();
                await this.fetchContractListEmit();
                await this.fetchContractListEmitConstant();
                
                this.selectedSearchEmit = [];
                this.selectedSearchEmitConstant = [];
                this.contractSelected = {};
                this.contractSelectedContrat = {};
                this.contractListItem = {};
                this.selectedSearch = [];
            }
        },
        async save() {

            console.log("item", this.selectedSearch);

            this.contractListItem = {};

            if(this.selectedSearch.length == 0) {
                this.$fun.alert("Debe seleccionar un contrato","warning");
                return
            }

            const newContractIds = this.selectedSearch.map(item => item.AmsID).sort();

            const existingGroups = await ContractList.validateGroup(newContractIds, this.TypeLaborDocument, this.$fun.getUserID());
            
            if (existingGroups.data && existingGroups.data.length > 0) {
                this.$fun.alert(`Ya existe un contrato con el mismo grupo (ID: ${existingGroups.data[0].AgcID})`, "warning");
                return;
            }

            if(this.TypeLaborDocument == 2) {
                if(this.selectedSearch.length > 1) {
                    this.$fun.alert("Para emitir una constancia de trabajo debe seleccionar solo un contrato","warning");
                    return;
                } else if(this.selectedSearch.length == 1 && this.selectedSearch[0].SecStatus !== 1) {
                    this.$fun.alert("El contrato seleccionado no está activo","warning");
                    return;
                }
            }
            
            this.contractListItem.PrsDocumentNumber = this.PrsDocumentNumber;
            this.contractListItem.NtpID = this.NtpID;
            this.contractListItem.PrsID = this.PrsID;
            this.contractListItem.TypeLaborDocument = this.TypeLaborDocument;
            this.contractListItem.SecStatus = 1;

            this.contractListItem.detail = this.selectedSearch.map(item => {
                return {
                    ...item,
                    UsrCreateID: this.$fun.getUserID(),
                    UsrUpdateID: this.$fun.getUserID()
                }
            });

            this.contractListItem.UsrCreateID = this.$fun.getUserID();
            this.contractListItem.UsrUpdateID = this.$fun.getUserID();
            
            const confirmSave = await this.$fun.alert("Seguro de guardar?", "question");
            if (confirmSave.value) {
                const resp = await ContractList.saveContract(this.contractListItem, this.$fun.getUserID());
                if (resp.status == 200) {
                    this.$fun.alert("Contrato Guardado","success");
                    await this.fetchContractListEmit();
                    await this.fetchContractListEmitConstant();
                    this.selectedSearch = [];
                    this.selectedSearchEmit = [];
                    this.selectedSearchEmitConstant = [];

                    await this.fetchContractList();
                }
            }
            
        },
        async downloadPdf() {
            if(this.selectedSearchEmit.length > 0) {

                const AgcID = this.selectedSearchEmit[0].AgcID;

                await ContractList.listCertificated(AgcID, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if(r.data.length > 0){
                                r.data.forEach(item => this.formatDates(item, this.dateKeys));

                                this.fullContractData = r.data;
                                this.isMultipleContracts = r.data.length > 1;

                                if(r.data.length > 1) {
                                    this.contractSelected = {
                                        PrsDocumentNumber: this.PrsDocumentNumber,
                                        NtpFullName: this.fullContractData[0].NtpFullName,
                                        PstName: this.fullContractData[0].PstName,
                                        AreName: this.fullContractData[0].AreName,
                                        SecCreate: this.fullContractData[0].SecCreate,
                                        details: this.fullContractData
                                    };
                                    this.isMultipleContracts = true;
                                } else {
                                    this.contractSelected = this.fullContractData[0];
                                    this.isMultipleContracts = false;
                                }
                                
                                this.dialogGuidePdf = true;
                            }
                        }
                    });
            }
            else{
                this.$fun.alert("Seleccione un contrato","warning");
            }
        },
        async downloadPdfContrat() {
            if(this.selectedSearchEmitConstant.length > 0) {

                const AgcID = this.selectedSearchEmitConstant[0].AgcID;
                
                await ContractList.listCertificated(AgcID, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if(r.data.length > 0){
                                r.data.forEach(item => this.formatDates(item, this.dateKeys));
                                this.contractSelectedContrat = r.data[0];
                                this.dialogGuidePdfContrat = true;
                            }
                        }
                    });
            }
            else{
                this.$fun.alert("Seleccione un contrato","warning");
            }
        },
        rowClickSearch: function(item, row) {
            if (item !== undefined) {
                if(item == this.selectedSearch[0]){
                    this.selectedSearch = [];
                }else{
                    this.selectedSearch = [item];
                }
            }
        },
        rowClickSearchEmit: function(item, row) {
            if (item !== undefined) {
                if (item == this.selectedSearchEmit[0]) {
                    this.selectedSearchEmit = [];
                }else{
                    this.selectedSearchEmit = [item];
                }
            }
        },
        rowClickSearchEmitConstant: function(item, row) {
            if (item !== undefined) {
                if (item == this.selectedSearchEmitConstant[0]) {
                    this.selectedSearchEmitConstant = [];
                }else{
                    this.selectedSearchEmitConstant = [item];
                }
            }
        },
        formatDates(obj, dateKeys) {
            for (let key of dateKeys) {
                if (obj[key]) {
                    let [year, month, day] = obj[key].split('T')[0].split('-'); // Divide la fecha en partes
                    obj[key] = `${day}/${month}/${year}`; // Reorganiza en formato DD/MM/YYYY
                }
            }
        },
        async textSearch(text){
            this.filter.Search = text;
            await this.fetchContractListEmit();
        },
        async textSearchContract(text){
            this.filter.Search = text;
            await this.fetchContractListEmitConstant();
        },
        async removeCertificate() {
            this.selectedSearchEmit[0].SecStatus = 0;
            this.selectedSearchEmit[0].SecUpdateID = this.$fun.getUserID();
            this.selectedSearchEmit[0].detail = [];
            
            const confirmSave = await this.$fun.alert("Seguro de Eliminar el Registro", "question");
            if (confirmSave.value) {
                const resp = await ContractList.saveContract(this.selectedSearchEmit[0], this.$fun.getUserID());
                if (resp.status == 200) {
                    console.log(resp);
                    this.$fun.alert("Actualizado Correctamente", "success");
                    await this.fetchContractListEmit();
                    await this.fetchContractListEmitConstant();
                    this.selectedSearch = [];
                    this.selectedSearchEmit = [];
                    this.selectedSearchEmitConstant = [];

                    await this.fetchContractList();
                }
            }
        },
        async removeConstant() {
            this.selectedSearchEmitConstant[0].SecStatus = 0;
            this.selectedSearchEmitConstant[0].SecUpdateID = this.$fun.getUserID();
            this.selectedSearchEmitConstant[0].detail = [];
            
            const confirmSave = await this.$fun.alert("Seguro de Eliminar el Registro", "question");
            if (confirmSave.value) {
                const resp = await ContractList.saveContract(this.selectedSearchEmitConstant[0], this.$fun.getUserID());
                if (resp.status == 200) {
                    console.log(resp);
                    this.$fun.alert("Actualizado Correctamente", "success");
                    await this.fetchContractListEmit();
                    await this.fetchContractListEmitConstant();
                    this.selectedSearch = [];
                    this.selectedSearchEmit = [];
                    this.selectedSearchEmitConstant = [];

                    await this.fetchContractList();
                }
            }
        }
    }
}
</script>