import Service from "../Service";
 
const resource = "HmnContractList/";

export default {

    list(PrsDocumentNumber, requestID) {
        return Service.post(resource + "list", {}, {
            params: { PrsDocumentNumber:PrsDocumentNumber, requestID: requestID },
        });
    },
    
    listEmit(parameters,requestID) {
        return Service.post(resource + "listEmit", parameters, {
            params: { requestID: requestID },
        });
    },
    listEmitConstant(parameters,requestID) {
        return Service.post(resource + "listEmitConstant", parameters, {
            params: { requestID: requestID },
        });
    },

    listCertificated(AgcID, requestID) {
        return Service.post(resource + "listCertificated", {}, {
            params: { AgcID:AgcID, requestID: requestID },
        });
    },

    saveContract(data, requestID) {
        return Service.post(resource + "saveContract", data, {
            params: { requestID: requestID },
        });
    },

    validateGroup(contractIds, TypeLaborDocument, requestID) {
        const contractIdsString = contractIds.join(',');
        return Service.post(resource + "validateGroup", {}, {
            params: {
                contractIds: contractIdsString,
                TypeLaborDocument: TypeLaborDocument,
                requestID: requestID
            }
        });
    }
};