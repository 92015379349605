<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion de Constancia"
                dark
                :color="'#8e8f91'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
                <hr>
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="'CONSTANCIA_' + this.contractSelectedContrat.NtpFullName + '_' + this.contractSelectedContrat.AmsStartDateMovement + '_' + this.contractSelectedContrat.AmsEndDateMovement"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
                <section slot="pdf-content">
                    <div style="
                        position: relative;
                        min-height: 118.7vh;
                        font-family: Arial, Helvetica, sans-serif;
                    ">  
                        <div style="padding: 100px 130px; font-family: Arial, Helvetica, sans-serif; line-height: 1.6; font-size: 12px;">
                            <div style="padding-bottom: 90px;">
                                <img src="/static/logo_sp.png" alt="" style="width: 180px; height: auto;">
                            </div>
                            <div style="text-align: center;">
                                <h1 style="
                                    font-size: 22px; 
                                    font-weight: bold; 
                                    font-family: 'Times New Roman';
                                ">CONSTANCIA DE TRABAJO</h1>
                                <span style="
                                    display: inline-block; 
                                    width: 54%; 
                                    border-bottom: 2px solid black;
                                    margin-bottom: 20px;
                                    margin-top: -7px;
                                "></span>
                            </div>
                            
                            <div style="font-size: 16px; margin-bottom: 50px; text-align: justify; font-weight: bold; font-family: 'Times New Roman';">
                                <p>
                                    <i>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SUNSHINE EXPORT S.A.C. con número de RUC 20329725431, y con domicilio en Carretera Tambo grande - Las Lomas Km. 1077+179 mt, del Distrito de Tambo grande, Provincia y Departamento de Piura, deja constancia que:
                                    </i>
                                </p>
                            </div>
                            <div style="font-size: 14px;">
                                <div style="margin-bottom: 50px; text-align: justify;">
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Que, el Sr. (a) <span style="font-weight: bold;">{{this.contractSelectedContrat.NtpFullName}}</span> con <span style="font-weight: bold;">DNI N° {{ this.contractSelectedContrat.PrsDocumentNumber }}</span> se encuentra laborando en nuestra empresa, sede de TAMBOGRANDE desde el <span style="font-weight: bold;">{{ this.contractSelectedContrat.AmsStartDateMovement }}</span> como <span style="font-weight: bold;">{{ this.contractSelectedContrat.PstName }}</span> en el área de <span style="font-weight: bold;">{{ this.contractSelectedContrat.AreName }}.</span></p>
                                </div>

                                <div style="margin-top: 40px; text-align: justify;">
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Se extiende el presente documento a solicitud de la parte interesada para los fines que estime convenientes.</p>

                                    <p style="margin-top: 40px; text-align: right;">
                                        Tambo grande, {{ formatDate(contractSelectedContrat.SecCreate) }}.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div style="
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                        ">
                            <!-- Footer -->
                            <div style="
                                text-align: center;
                                font-size: 14px;
                                padding: 25px;
                            ">
                                <p style="margin: 0; font-weight: bold;">
                                    Carretera Tambogrande - Las Lomas Km.1077 + 179m; Tambogrande - Piura - Perú Telf.: +51 73 368262 / +51 73 368031 / www.sunshineperu.com
                                </p>
                            </div>

                            <!-- Red Gradient Bar -->
                            <div style="
                                height: 32px;
                                background: linear-gradient(to right, #FF0000, #FF6B00);
                            "></div>
                        </div>
                    </div>
                </section>
                </vue-html2pdf>
        </v-card>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";
    import qrCode from "vue-qr-generator";

    export default{ 
       
        props:{
            contractSelectedContrat: {
				type: Object,
				default: null,
			},
        },
        components:{
            VueHtml2pdf,
            qrCode
        },
        data(){
            return{
                urlData: "",
            }
        },
        methods:{ 
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
                console.log(`On Before PDF Generation`)
                    html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                        // const totalPages = pdf.internal.getNumberOfPages()
                        // for (let i = 1; i <= totalPages; i++) {
                        // 	pdf.setPage(i)
                        // 	pdf.setFontSize(10)
                        // 	pdf.setTextColor(150)
                        // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        // } 
                    }).save()
            },
            formatDate(dateString) {
                const [day, month, year] = dateString.split('/');
                const months = {
                '01': 'enero',
                '02': 'febrero',
                '03': 'marzo',
                '04': 'abril',
                '05': 'mayo',
                '06': 'junio',
                '07': 'julio',
                '08': 'agosto',
                '09': 'septiembre',
                '10': 'octubre',
                '11': 'noviembre',
                '12': 'diciembre'
                };
                return `${parseInt(day)} de ${months[month]} del ${year}`;
            }
        },
        mounted(){
            //console.log('print', this.contractSelectedContrat);
        }
    }
</script>